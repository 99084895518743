<template>
  <section class="feature-sec">
    <div class="hero">
      <div class="feature-title">
        {{ benefitTitle }}
      </div>

      <!-- RENDER this if Service is not QUEUE MANAGEMENT -->
      <div v-if="id !== 'queue_management'" class="f-items-main">
        <div v-for="(item, index) in benefits" :key="index" class="f-items">
          <div class="f-icon">
            <img :class="imgClass" :src="getImg(item.image)" alt />
          </div>
          <p v-html="getString(item.description)" />
        </div>
      </div>

      <!-- RENDER this if Service is QUEUE MANAGEMENT -->
      <div v-else class="queue-gifs">
        <div
          v-for="(item, index) in benefits"
          :key="index"
          class="queue-gif-item"
        >
          <div class="queue-gif-image-container">
            <img :src="getImg(item.gif)" alt="queue benefit image" />
          </div>
          <div class="queue-gif-text" v-html="getString(item.description)" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    benefits: {
      type: Array,
      required: true
    },
    getString: {
      type: Function
    },
    id: String,
    benefitTitle: String,
    getImg: Function
  },
  computed: {
    imgClass() {
      return this.id === "gis" ? "ic-salmon-color" : "";
    }
  }
};
</script>

<style></style>

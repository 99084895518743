<template>
  <section class="hero queue-hero" :style="`background-image: url(${getBannerImage()}); background-size: cover;`">
    <div class="hero-white-box-top"></div>

    <div class="hero-border-left"></div>

    <div class="hero-block fade-in-bck">
      <div class="hero-title fade-in-bck">
        <span class="hero-title-ad" v-html="serviceTitleBreak"></span>
        <span class="hero-title-bar fade-in-bck"></span>
      </div>

      <div class="hero-subtitle">
        <span class="hero-subtitle-ad">{{ serviceDescription }}</span>

        <div class="cta-button-container">
            <a
            :href="`mailto:${mailTo}?subject=${emailSubject}`"
            data-mixpanel="cortico_cta_headline"
            onclick="gtag_report_conversion()"
            class="main-portfolio-cta btn-countable btn-outline btn-outline-yellow btn-cortico-cta-header"
            >BOOK A DEMO
          </a>

          <a
            v-if="isMES"
            data-mixpanel="cortico_cta_download"
            href="/Countable_MES_Sheet.pdf"
            class="main-portfolio-cta btn-countable btn-outline btn-outline-red"
            download
            >Download Product Data Sheet PDF
          </a>

        </div>
        
      </div>

      <div :class="illustrationImg">
        <img v-if="image !== ''" :src="image" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    serviceTitle: { type: String },
    serviceDescription: { type: String },
    emailSubject: { type: String },
    image: String,
    id: String,
    mailTo: { type: String, default: 'info@countable.ca' },
    bannerImg: { type: Object, default: () => {} }
  },
  computed: {
    illustrationImg() {
      return this.id === "gis"
        ? "gis-illustration"
        : "cortico-doctor-illustration";
    },
    serviceTitleBreak() {
      return this.serviceTitle.split(":").join(":<br />");
    },
    isMES() {
      return this.id === 'manufacturing-execution-system'
    }
  },
  methods: {
    getBannerImage() {
      if(this.bannerImg && this.bannerImg.url) {
        return this.bannerImg.url
      }
    }
  }

};
</script>

<style lang="scss">
  .cta-button-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;

    & > * {
      width: auto !important;
      max-width: fit-content !important;
      margin-right: 0.5em;
    }
  }
</style>

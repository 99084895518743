<template>
  <main :class="`portfolio ${service.service_slug}`" v-if="service">
    <ServiceBanner
      :id="service.service_slug"
      :service-title="service.title"
      :service-description="service.description"
      :email-subject="`Tell Me More About Countable's ${service.service_name}`"
      :image="returnImage(service.image)"
      :mail-to="service.emailTo"
      :banner-img="service.bannerImg"
    />

    <Benefits
      v-if="service.benefits.length !== 0"
      :id="service.service_slug"
      :benefits="service.benefits"
      :benefit-title="service.benefit_title"
      :get-string="getString"
      :get-img="returnImage"
    />

    <template v-if="getFeaturesLength">
      <FeaturesSection
        :id="service.service_slug"
        :features="service.features"
        :email-subject="
          `Tell Me More About Countable's ${service.service_name}`
        "
        :get-string="getString"
        :mail-to="service.emailTo"
      >
        <!-- If Service is Cortico -->
        <template v-if="isCortico" v-slot:top-features>
          <div class="top-result">
            <div class="reslt-items left">
              <p>
                The way people interact with their health information has
                changed for good.
                <br />
                <br />That’s why we automated patient engagement.
              </p>
            </div>

            <div class="reslt-items phone">
              <img src="@/assets/images/tablet phone.svg" alt />
            </div>

            <div class="reslt-items percnt">
              <h2>77%</h2>
              <p>
                of patients want access to
                <br />Virtual Care and/or
                <br />Telehealth
              </p>
              <span>- The Virtual Visits Consumer Society</span>
            </div>

            <div class="reslt-items percnt">
              <h2>98%</h2>
              <p>
                of patients prefer patient
                <br />portals or digital tools
              </p>
              <span>- American Journal of Managed Care</span>
            </div>
          </div>
        </template>
        <!-- If Service is GIS -->
        <template v-else-if="isGIS" v-slot:top-features>
          <div class="top-result gis-results">
            <div class="reslt-items map">
              <img
                class="ic-salmon-color"
                src="@/assets/images/stats-banner-device-with-pin.svg"
                alt
              />
            </div>

            <div class="reslt-items percnt wide">
              <p>
                <strong>More map service providers</strong> are realizing the
                cost-saving and flexibility benefits of web-based mapping
                instead of GIS-based software
              </p>
              <span>
                - Paul Ramsey, Executive Geospatial Engineer at Crunchy
                Data
              </span>
            </div>
          </div>
        </template>
        <!-- End of Service is GIS -->
        <template v-if="isCortico" v-slot:bottom-features>
          <div class="osmer-logo-sec">
            <div class="omser-left">
              <!-- <span></span>  -->
              <img
                src="@/assets//images/oscar-logo.png"
                alt="Oscar patient engagement software logo"
              />
            </div>

            <div class="omser-right">
              <p>
                Our automated patient engagement software
                <br />securely and easily integrates with OSCAR EMR.
              </p>
            </div>
          </div>
        </template>
        <!-- End If Service is Cortico -->
      </FeaturesSection>
    </template>

    <section class="queue-case-studies" v-if="service.projects.length !== 0">
      <div class="queue-case-studies-title">
        <span class="queue-case-studies-strong">CASE STUDIES:</span> WHO WE'VE
        SOLVED PROBLEMS FOR
      </div>

      <CaseStudy
        v-for="(caseStudy, index) in service.projects"
        :key="index"
        :id="caseStudy.id"
        :get-img="returnImage"
      />
    </section>
  </main>
  <div v-else class="loading-section">
    <div class="loader-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
import ServiceBanner from "@/components/Services/ServiceBanner";
import Benefits from "@/components/Services/Benefits";
import FeaturesSection from "@/components/Services/FeaturesSection";
import CaseStudy from "@/components/Services/CaseStudy";
import { mapGetters } from "vuex";
import API from "@/api/utils";

export default {
  components: {
    ServiceBanner,
    Benefits,
    FeaturesSection,
    CaseStudy
  },
  data() {
    return {};
  },
  computed: {
    service() {
      return this.$store.getters.getService(this.$route.params.service);
    },
    getFeaturesLength() {
      return this.service.features.length !== 0;
    },
    isCortico() {
      return this.service.service_slug === "cortico";
    },
    isGIS() {
      return this.service.service_slug === "gis";
    }
  },
  methods: {
    getString(string) {
      return string.replace(/(.{20}[^\s]*)/g, "$1<br>");
    },
    returnImage(img) {
      const host = API.getBaseURL();
      const imgLoc = img ? `${host}${img.url}` : "";
      return imgLoc;
    }
  }
};
</script>

<style></style>

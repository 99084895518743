<template>
  <section class="result-sec">
    <div class="hero">
      <div :class="innerResult + 'result-inner'">
        <slot name="top-features"></slot>

        <div class="bottom-result">
          <div
            v-for="(feature, index) in features"
            :key="index"
            :class="`bottom-items-info ${itemInfo}`"
          >
            <h5>{{ feature.title }}</h5>
            <p v-html="getString(feature.description)" />
          </div>

          <a
            :href="`mailto:${mailTo}?subject=${emailSubject}`"
            data-mixpanel="cortico_cta_mid_section"
            onclick="gtag_report_conversion()"
            class="main-portfolio-cta btn-countable btn-outline btn-outline-red"
          >BOOK A DEMO</a>
        </div>
      </div>

      <!---Show this if Service is Cortico --->

      <slot name="bottom-features"></slot>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    features: {
      type: Array
    },
    emailSubject: {
      type: String
    },
    getString: Function,
    id: String,
    mailTo: {type: String, default: ''}
  },
  computed: {
    innerResult() {
      return this.id === "gis" ? "gis-" : "";
    },
    itemInfo() {
      return this.id === "gis" ? "gis-item-info" : "";
    }
  }
};
</script>

<style></style>

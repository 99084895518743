<template>
  <div class="queue-case-study" v-if="hasFetched">
    <div class="queue-case-header">
      <div class="queue-case-study-title">{{ project.client.name }}</div>

      <div class="queue-case-study-logo-container">
        <img :src="getImg(project.client.image)" />
      </div>
    </div>

    <div class="queue-case-body">
      <div class="queue-case-body-column-left">
        <div class="queue-case-study-screenshot-container">
          <img
            :src="getImg(project.image)"
            alt="Queue Screens - Citizen services"
          />
        </div>
      </div>

      <div class="queue-case-body-column-right">
        <div class="queue-case-study-job-description">
          {{ project.results }}
        </div>

        <div v-if="featuresLength" class="queue-case-study-features">
          <h1>FEATURES</h1>

          <ul>
            <li v-for="feature in project.features" :key="feature.id">
              {{ feature.title }}
            </li>
          </ul>
        </div>

        <div v-if="project.testimonial" class="queue-case-study-testimonial">
          <div class="queue-case-study-testimonial-quote">“</div>
          {{ project.testimonials[0].testimony }}
          <div class="queue-case-study-testimonial-author">
            - {{ project.testimonials[0].client_name }},
            {{ project.testimonials[0].client_title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: Number,
    getImg: Function
  },
  data() {
    return {
      project: {},
      hasFetched: false
    };
  },
  computed: {
    featuresLength() {
      return this.project.features ? this.project.features.length !== 0 : false;
    },
    hasTestimonial() {
      return Object.keys(this.project.testimonial).length;
    }
  },
  mounted() {
    this.$store.dispatch("fetchCaseStudy", this.id).then(response => {
      this.hasFetched = true;
      this.project = response;
    });
  }
};
</script>

<style></style>
